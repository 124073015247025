<template>
  <TransitionRoot as="template" :show="open">
    <Dialog as="div" class="fixed z-10 inset-0 overflow-y-auto" >
      <div class="flex items-center justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
        <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0" enter-to="opacity-100" leave="ease-in duration-200" leave-from="opacity-100" leave-to="opacity-0">
          <DialogOverlay class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </TransitionChild>

        <!-- This element is to trick the browser into centering the modal contents. -->
        <span class="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
        <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95" enter-to="opacity-100 translate-y-0 sm:scale-100" leave="ease-in duration-200" leave-from="opacity-100 translate-y-0 sm:scale-100" leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
          <div class="relative inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-visible shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6">
            <div class="sm:flex sm:items-start">
              <div :class="[iconColor !== undefined ? `bg-${iconColor}-100` : 'bg-red-100', 'mx-auto flex h-12 w-12 items-center justify-center rounded-full']">
                <ExclamationIcon :class="[iconColor !== undefined ? `text-${iconColor}-600` : 'text-red-600', 'h-6 w-6 ']" aria-hidden="true" />
              </div>
              <div class="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                <DialogTitle as="h3" class="text-base font-semibold leading-6 text-gray-900">{{modalData.headerText}}</DialogTitle>
                <div class="mt-2">
                  <p class="text-sm text-gray-500">{{modalData.bodyText}}</p>
                </div>
              </div>
            </div>

            <div class="mt-2">
              <div class="flex justify-between select-none">
                <label for="name" class="block text-sm font-semibold leading-6 text-gray-700 uppercase">Nazwa</label>
                <span class="text-sm leading-6 text-gray-500 uppercase" id="name-optional" v-tooltip="`Jeśli nazwa będzie pusta, ustawi się automatycznie dzisiejsza data.`">Opcjonalne</span>
              </div>
              <div class="mt-1">
                <input type="text" v-model="this.name" name="name" id="name" class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" placeholder="XXXX" aria-describedby="name-optional" />
              </div>
            </div>

            <slot></slot>
            <div class="mt-5 sm:mt-6 sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3">
              <button :disabled="buttonBlocked" type="button" :class="[buttonBlocked ? `opacity-40 hover:bg-${buttonColor}-600 cursor-not-allowed` : '', buttonColor !== undefined ? `bg-${buttonColor}-600 hover:bg-${buttonColor}-500 focus:ring-${buttonColor}-500` : 'bg-red-600 hover:bg-red-500 focus:ring-red-500', 'transition-all duration-300 inline-flex w-full justify-center rounded-md  px-3 py-2 text-sm font-semibold text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 sm:col-start-2']" @click="emitAccept">{{modalData.acceptButtonText}}</button>
              <button type="button" class="transition-all duration-300 mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:col-start-1 sm:mt-0" @click="close()" ref="cancelButtonRef">Anuluj</button>
            </div>
          </div>
        </TransitionChild>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<script>
import { ref } from 'vue'
import { Dialog, DialogOverlay, DialogTitle, TransitionChild, TransitionRoot } from '@headlessui/vue'
import { ExclamationIcon } from '@heroicons/vue/outline'
export default {
  components: {
    Dialog,
    DialogOverlay,
    DialogTitle,
    TransitionChild,
    TransitionRoot,
    ExclamationIcon,
  },
  props: {
    modalData: Object, 
    buttonColor: String, 
    iconColor: String,
    buttonBlocked: {
      type: Boolean,
      default: false
    },
    closeModalAfterAccept: {
      type: Boolean,
      default: true
    } 
  },
  data() {
    return {
      name: '',
      open: true
    }
  },
  methods: {
    emitClose()
    {
      this.$emit("closeModal");
    },
    emitAccept()
    {
      this.$emit("accept", this.name);
      if(this.closeModalAfterAccept) {
        this.$emit("closeModal");
      }
    },
    close()
    {
      this.emitClose();
      this.open = false
    }
  },
}
</script>
