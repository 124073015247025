<template>
  <TransitionRoot as="template" :show="true">
    <Dialog as="div" class="fixed z-10 inset-0 overflow-y-auto" >
      <div class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
        <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0" enter-to="opacity-100" leave="ease-in duration-200" leave-from="opacity-100" leave-to="opacity-0">
          <DialogOverlay class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </TransitionChild>
        <!-- This element is to trick the browser into centering the modal contents. -->
        <span class="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
        <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95" enter-to="opacity-100 translate-y-0 sm:scale-100" leave="ease-in duration-200" leave-from="opacity-100 translate-y-0 sm:scale-100" leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
          <div class="relative inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-visible shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6">
            <div>
              <div class="sm:flex sm:items-start">
                <div class="bg-green-100 mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full  sm:mx-0 sm:h-10 sm:w-10">
                  <UserAddIcon class="text-green-600 h-6 w-6" aria-hidden="true" />
                </div>
                <div class="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                  <DialogTitle as="h3" class="text-lg leading-6 font-medium text-gray-900">Dodaj producenta</DialogTitle>
                  <div class="mt-2">
                    <p class="text-sm text-gray-500">Dodaj producenta do produktu <a :href="`/dashboard/products/list/viewProduct?id=${product.id}`" target="_blank" class="font-bold ">{{product.name}}</a> oraz jego wariantów.</p>
                  </div>
                </div>
              </div>
              <div class="flex flex-col mt-6">
                  <label class="flex h-full text-sm font-medium leading-6 text-gray-900 select-none items-center">Wybierz producenta<span class="text-red-500">*</span></label>
                  <Combobox class="" as="div" v-model="selectedManufacturer">
                      <div class="relative mt-1 max-w-lg">
                      <ComboboxInput class="w-full rounded-md border border-gray-300 bg-white py-2 pl-3 pr-10 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-1 focus:ring-indigo-500 sm:text-sm" @change="manufacturerQuery = $event.target.value" :display-value="(item) => allManufacturers.length ? (item ? `${item.name}` : `Wybierz producenta`) : 'Brak producentów' " />
                      <ComboboxButton class="absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 focus:outline-none">
                          <ChevronDownIcon class="h-5 w-5 text-gray-400" aria-hidden="true" />
                      </ComboboxButton>
                      <ComboboxOptions v-if="filteredManufacturers.length > 0"  class="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                          <ComboboxOption v-for="manufacturer in filteredManufacturers" :key="manufacturer.ref.id" :value="manufacturer" as="template" v-slot="{ active, selected }">
                          <li :class="['relative cursor-default select-none py-2 pl-3 pr-9', active ? 'bg-indigo-600 text-white' : 'text-gray-900']">
                              <span :class="['block truncate', selected && 'font-semibold']">
                                  <span class="mx-3" >{{`${manufacturer.name}`}}</span>
                              </span>
                              <span v-if="selected" :class="['absolute inset-y-0 right-0 flex items-center pr-4', active ? 'text-white' : 'text-indigo-600']">
                                  <CheckIcon class="h-5 w-5" aria-hidden="true" />
                              </span>
                          </li>
                          </ComboboxOption>
                      </ComboboxOptions>
                      </div>
                  </Combobox>
              </div>
              <div class="flex justify-between mt-1">
                <p v-if="typeof product.manufacturer === 'string'" class="text-xs text-gray-500">Sugerowany producent: <b>{{product.manufacturer || "Brak"}}</b></p>  
                <p v-else class="text-xs text-gray-500">Sugerowany producent: <b>{{product.manufacturer?.name || "Brak"}}</b></p>  
                <a href="/dashboard/manufacturers/add" target="_blank" class="text-xs text-blue-500 hover:text-blue-700 tranisiton duration-200">Brak producentana na liście</a>  
              </div>  
              <div class="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                <button @click="addManufacturer()" type="button" :disabled="!validation" :class="[validation ? 'bg-green-600 focus:ring-green-500 focus:ring-2 focus:ring-offset-2' : 'bg-green-200 cursor-default', 'w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 text-base font-medium text-white focus:outline-none sm:ml-3 sm:w-auto sm:text-sm']">Dodaj</button>
                <button @click="$emit('close')" type="button" class="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:w-auto sm:text-sm">Anuluj</button>
              </div>
            </div>
          </div>
        </TransitionChild>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<script>
import { Dialog, DialogOverlay, DialogTitle, TransitionChild, TransitionRoot } from '@headlessui/vue'
import { UserAddIcon, CheckIcon, ChevronDownIcon } from '@heroicons/vue/outline'
import { Combobox, ComboboxButton, ComboboxInput, ComboboxLabel, ComboboxOption, ComboboxOptions } from '@headlessui/vue'
import LoadingSpinnerHub from './LoadingSpinnerHub.vue'
export default {
  props: ["allManufacturers", "product"],
  components: {
    Dialog, DialogOverlay, DialogTitle,
    TransitionChild, TransitionRoot,
    Combobox, ComboboxButton, ComboboxInput, ComboboxLabel, ComboboxOption, ComboboxOptions,
    UserAddIcon, CheckIcon, ChevronDownIcon,
    LoadingSpinnerHub
  },
  data() {
    return {
      manufacturerQuery: "",
      selectedManufacturer: null,
    }
  },
  methods: {
    addManufacturer() {
      this.$emit("addManufacturer", {
        selectedManufacturer: this.selectedManufacturer,
        product: this.product,
      })
      this.$emit("close")
    },
  },
  computed: {
    filteredManufacturers() {
      if(!this.manufacturerQuery) {
        return this.allManufacturers
      } else {
        return this.allManufacturers.filter((item) => item.name.toLowerCase().includes(this.manufacturerQuery.toLowerCase()))
      }
    },
    validation() {
      if(!this.selectedManufacturer) {
        return false
      } 
      return true
    }
  },
}
</script>
