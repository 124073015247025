<template>
    <div>
        <nav class="hidden md:flex mb-4 h-8" aria-label="Breadcrumb">
            <ol role="list" class="flex space-x-4 rounded-md bg-white px-6 shadow">
            <li class="flex">
                <div class="flex items-center">
                <router-link to="/hub" class="transition ease-in-out duration-300 text-gray-400 hover:text-gray-500">
                    <HomeIcon class="h-5 w-5 flex-shrink-0" aria-hidden="true" />
                    <span class="sr-only">Home</span>
                </router-link>
                </div>
            </li>
            <li class="flex">
                <div class="flex items-center">
                <svg class="h-full w-6 flex-shrink-0 text-gray-200" viewBox="0 0 24 44" preserveAspectRatio="none" fill="currentColor" xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
                    <path d="M.293 0l22 22-22 22h1.414l22-22-22-22H.293z" />
                </svg>
                <router-link to="/dashboard/storage" class="transition ease-in-out duration-300 ml-4 text-xs font-medium text-gray-500 hover:text-gray-700" aria-current="page">Moduł magazyn</router-link>
                </div>
            </li>
            <li class="flex">
                <div class="flex items-center">
                <svg class="h-full w-6 flex-shrink-0 text-gray-200" viewBox="0 0 24 44" preserveAspectRatio="none" fill="currentColor" xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
                    <path d="M.293 0l22 22-22 22h1.414l22-22-22-22H.293z" />
                </svg>
                <div class="ml-4 text-xs font-medium text-gray-400 cursor-default" aria-current="page">Złóz zamówienie</div>
                </div>
            </li>
            </ol>
        </nav>
        <router-link to="/dashboard/storage" class="block md:hidden transition ease-in-out duration-300 mb-2 text-sm font-medium text-blue-500 hover:text-blue-700" aria-current="page">&larr; Cofnij</router-link>
    </div>
    <div class="mb-4 mt-6">
        <div class="sm:hidden">
            <label for="tabs" class="sr-only">Select a tab</label>
            <!-- Use an "onChange" listener to redirect the user to the selected tab URL. -->
            <select id="tabs" name="tabs" class="block w-full rounded-md border-gray-300 focus:border-indigo-500 focus:ring-indigo-500">
                <option v-for="tab in tabs" :key="tab.name" :selected="tab.current">{{ tab.name }}</option>
            </select>
        </div>
        <div class="hidden sm:block select-none">
            <nav class="flex justify-between items-center" aria-label="Tabs">
                <div class="flex flex-row gap-2">
                    <a @click="changeTab(0)" :class="[tabs[0].current ? 'bg-indigo-100 text-indigo-700' : 'text-gray-500 hover:text-gray-700', 'rounded-md px-3 py-2 text-sm font-medium transition-all duration-300']" :aria-current="tabs[0].current ? 'page' : undefined">{{ tabs[0].name }}</a>
                    <a @click="changeTab(1)" :class="[tabs[1].current ? 'bg-indigo-100 text-indigo-700' : 'text-gray-500 hover:text-gray-700', 'rounded-md px-3 py-2 text-sm font-medium transition-all duration-300']" :aria-current="tabs[1].current ? 'page' : undefined">{{ tabs[1].name }}</a>
                </div>
                <a @click="changeTab(2)" :class="[tabs[2].current ? 'bg-indigo-100 text-indigo-700' : 'text-gray-500 hover:text-gray-700', 'rounded-md px-3 py-2 text-sm font-medium transition-all duration-300']" :aria-current="tabs[2].current ? 'page' : undefined">{{ tabs[2].name }}</a>
            </nav>
        </div>
    </div>

    <div class="relative mb-6">
        <div class="absolute inset-0 flex items-center" aria-hidden="true">
            <div class="w-full border-t border-gray-300" />
        </div>
        <div class="relative flex justify-center">
            <span class="bg-gray-100 px-2 text-gray-500">
                <ChevronDownIcon class="h-5 w-5 text-gray-500" aria-hidden="true" />
            </span>
        </div>
    </div>


    <LoadingSpinnerHub v-if="loadedProducts" />
    <Transition name="fade">
    <div v-if="!loadedProducts && this.tabs[2].current" class="pb-8">
        <!-- SEARCHER -->
        <div class="flex mb-4">
            <div class="flex mr-4">
                <label for="search" class="sr-only">Szukaj</label>
                <div class="relative">
                    <div class="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                        <SearchIcon class="h-5 w-5 text-gray-400" aria-hidden="true" />
                    </div>
                    <input :disabled="this.searcher.locked" v-model="searcher.phrase" id="search" name="search" :class="['block w-full rounded-md border border-gray-300 bg-white py-2 pl-10 pr-3 text-sm placeholder-gray-500 focus:border-blue-500 focus:text-gray-900 focus:placeholder-gray-400 focus:outline-none focus:ring-1 focus:ring-blue-500 sm:text-sm', this.searcher.locked ? 'opacity-50' : '']" placeholder="Wyszukaj" type="search" />
                </div>
                <button :disabled="this.searcher.locked" type="button" @click.prevent="search" :class="['transition ease-in-out duration-300 ml-2 inline-flex items-center rounded border border-transparent bg-blue-100 px-2.5 py-1.5 text-xs font-medium text-blue-700', this.searcher.locked || this.searcher.phrase.length === 0 ? 'opacity-50 cursor-default' : 'hover:bg-blue-200 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2']">Szukaj</button>
            </div>
        </div>
        <div class="mt-4" v-if="searcher.found.length > 0">
            <span class="inline-flex items-center py-0.5 pl-2 pr-0.5 rounded-full text-xs font-medium bg-blue-100 text-blue-700">
                Wynik wyszukiwania dla frazy: "{{searcher.lastCall.phrase}}" (Znaleziono: {{searcher.found.length}})
                <button @click.prevent="resetSearch"  type="button" class="transition ease-in-out duration-300 flex-shrink-0 ml-0.5 h-4 w-4 rounded-full inline-flex items-center justify-center text-blue-400 hover:bg-blue-200 hover:text-blue-500 focus:outline-none focus:bg-blue-500 focus:text-white">
                    <svg class="h-2 w-2" stroke="currentColor" fill="none" viewBox="0 0 8 8">
                        <path stroke-linecap="round" stroke-width="1.5" d="M1 1l6 6m0-6L1 7" />
                    </svg>
                </button>
            </span>   
        </div>
        <div class="mx-auto max-w-3xl lg:max-w-7xl">
            <h1 class="sr-only">Page title</h1>
            <!-- Main 3 column grid -->
            <div class="grid grid-cols-1 items-start gap-4 lg:grid-cols-3 lg:gap-8">
                <!-- Left column -->
                <div class="grid grid-cols-1 gap-4 lg:col-span-2">
                    <section aria-labelledby="section-1-title">
                    <h2 class="sr-only" id="section-1-title">Section title</h2>
                    <div class="overflow-hidden rounded-lg bg-white shadow">
                        <!-- Your content -->
                        <table class="min-w-full divide-y divide-gray-300">
                            <thead class="bg-gray-50">
                                <tr>
                                    <th scope="col" class="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">Produkt</th>
                                    <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">EAN / SKU</th>
                                    <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Atrybuty</th>
                                    <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Warianty</th>
                                    <th scope="col" class="pr-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                        <span class="sr-only">Informacje</span>
                                    </th>
                                </tr>
                            </thead>
                            <tbody class="divide-y divide-gray-200 " :class="checkManufacturer(product) ? 'bg-white' : 'bg-gray-200'" v-for="(product,index) in searcher.found.length > 0 ? searcher.found : products" :key="index">
                            <tr>
                                <td class="whitespace-nowrap py-4 text-sm sm:pl-6">
                                <div class="flex items-center">
                                    <div class="h-10 w-10 flex-shrink-0">
                                        <img class="h-10 w-10 rounded-md object-contain" :src="product.image.url.length>0 ? product.image.url : `https://api.dicebear.com/7.x/initials/svg?seed=${`Product${product.id}`}`" :alt="product.name" />
                                    </div>
                                    <div class="ml-4">
                                        <div class="font-medium text-gray-900" v-tooltip="`${product.name}`">{{product.name.slice(0, 20) + `${product.name.length > 20 ? '...' : ''}`}}
                                            <span class="ml-1 transition ease-in-out duration-300 text-xs text-blue-300 hover:text-blue-500 select-none cursor-pointer" @click.prevent="copyToClipboard(product.id)">Kopiuj ID</span>
                                        </div>
                                        <div class="text-gray-500">
                                            <span class="text-xs" v-if="product.category.name.length>0">
                                                {{product.category.name}}
                                            <span class="text-xs opacity-60">(ID kategorii:{{product.category.id}})</span>
                                            </span>
                                            <span class="text-xs" v-if="product.category.name.length===0">Brak kategorii</span>
                                        </div>
                                    </div>
                                </div>
                                </td>
                                <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500 select-none">
                                    <div class="text-gray-500">
                                        <span class="text-xs" v-if="product.ean.length>0">
                                            <span class="text-gray-700 font-medium">EAN: </span>{{product.ean}}</span><span class="text-xs font-medium opacity-60" v-if="product.ean.length===0">Brak EAN</span><span class="transition ease-in-out duration-300 ml-2 text-xs text-blue-300 hover:text-blue-600 select-none cursor-pointer" v-if="product.ean.length>0" @click.prevent="copyToClipboard(product.ean)">Kopiuj</span></div>
                                    <div class="text-gray-500"><span class="text-xs" v-if="product.sku.length>0"><span class="text-gray-700 font-medium">SKU: </span>{{product.sku}}</span><span class="text-xs font-medium opacity-60" v-if="product.sku.length===0">Brak SKU</span><span class="transition ease-in-out duration-300 ml-2 text-xs text-blue-300 hover:text-blue-600 select-none cursor-pointer" v-if="product.sku.length>0" @click.prevent="copyToClipboard(product.sku)">Kopiuj</span></div>
                                </td>
                                <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500 select-none">
                                    <div>
                                        <span v-for="value in product.attribs.list.slice(0, 1)" :key="value.id" @click="showModal(product)"  class="transition ease-in-out duration-300 select-none w-fit inline-flex items-center px-2.5 py-0.5 rounded-md text-xs font-medium bg-gray-100 text-gray-600 cursor-pointer hover:bg-blue-100 hover:text-blue-400"> {{value.parent.name}}: {{value.name}} </span>
                                        <span class="transition ease-in-out duration-300 text-xs px-2.5 py-0.5 text-blue-300 hover:text-blue-500 cursor-pointer" v-if="product.attribs.list.length-1 > 0" @click="showModal(product)">+ {{product.attribs.list.length-1}} więcej</span>
                                        <span class="text-xs" v-if="product.attribs.list.length === 0">Brak</span>
                                    </div>
                                </td>
                                <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-700 select-none">
                                    <div class="w-28">
                                        <span class="w-full justify-center inline-flex items-center rounded-full bg-blue-50 px-2 py-1 text-xs font-medium text-blue-700 ring-1 ring-inset ring-blue-700/10" v-if="Object.keys(product.variants).length > 0">{{Object.keys(product.variants).length}} {{Object.keys(product.variants).length === 1 ? "wariant" : Object.keys(product.variants).length > 1 && Object.keys(product.variants).length < 5 ? "warianty" : "wariantów"}}</span>
                                        <span class="text-xs" v-if="Object.keys(product.variants).length === 0">Brak wariantów</span>
                                    </div>
                                </td>
                                <td v-if="checkManufacturer(product)" class="whitespace-nowrap py-4 pr-3 text-xs cursor-pointer">
                                    <div v-auto-animate class="flex h-6 items-center text-gray-500" v-tooltip="`${Object.keys(product.variants).length > 0 ? ' Wyświetl szczegóły produktu' : 'Utwórz nowy wariant produktu.'}`" @click="Object.keys(product.variants).length > 0 ? openAdditionalInformation(index,product) : this.$router.push(`/dashboard/products/list/editProduct?id=${product.id}`)">
                                        <EyeIcon class="h-6 w-6" v-if="Object.keys(product.variants).length > 0 && !this.toggleInformation[index].open" />  
                                        <EyeOffIcon class="h-6 w-6" v-if="this.toggleInformation[index].open" />
                                        <MailOpenIcon class="h-6 w-6" v-if="Object.keys(product.variants).length === 0" />
                                    </div>
                                </td>
                                <td v-else class="whitespace-nowrap py-4 pr-5 text-xs">
                                    <button v-tooltip="`Dodaj producenta z listy.`" @click.prevent="openSelectManufacturerModal(product)" type="button" class="transition-all duration-300 rounded-full bg-indigo-600 p-1 text-white shadow-sm hover:bg-indigo-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">
                                        <PlusIcon class="h-4 w-4" aria-hidden="true" />
                                    </button>
                                </td>
                            </tr>
                            <td colspan="12" v-if="this.toggleInformation[index].open" class="bg-gray-100 border-t border-gray-300 w-full">
                                <table class="min-w-full divide-y divide-gray-300">
                                    <thead class="bg-gray-100 w-full">
                                        <tr>
                                            <th scope="col" class="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">Warianty</th>
                                            <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Ilość Sztuk</th>
                                            <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"></th>
                                        </tr>
                                        <tr v-for="(variant,index) in createPositionsList(product)" :key="index">
                                            <td class="border-t border-gray-300 whitespace-nowrap py-4 pl-4 pr-3 text-sm sm:pl-6">
                                                <div class="flex items-center">
                                                    <div class="h-10 w-10 flex-shrink-0">
                                                        <img class="h-10 w-10 rounded-md object-contain" :src="variant.item.image.url.length>0 ? variant.item.image.url : `https://api.dicebear.com/7.x/initials/svg?seed=${`Product${variant.item.id}`}`" :alt="variant.item.id" />
                                                    </div>
                                                    <div class="ml-4" >
                                                        <div class="font-medium text-gray-900">{{variant.item.name}} <span class="transition ease-in-out duration-300 text-xs text-blue-300 hover:text-blue-500 select-none cursor-pointer" @click.prevent="copyToClipboard(variant.item.id)">Kopiuj ID</span></div>
                                                    </div>
                                                </div>
                                            </td>
                                            <td class="border-t border-gray-300 whitespace-nowrap px-3 py-4 text-sm text-gray-500 select-none">
                                                <div :class="['text-gray-400 font-medium', variant.item.quantity < 10 ? 'text-red-700 font-medium':'']"><span class="text-xs" ><span class="text-gray-700 font-medium ">Ilość: </span>{{variant.item.quantity}}</span></div>
                                            </td>
                                            <td class="border-t border-gray-300 whitespace-nowrap px-3 py-4 text-sm text-gray-500 select-none">
                                                <div class="flex justify-center">
                                                    <button @click="addVariantToList(variant)" type="button" class="rounded bg-white px-2 py-1 text-xs font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50">Dodaj</button>
                                                </div>
                                            </td>
                                        </tr>
                                    </thead>
                                </table>
                            </td>
                            </tbody>
                        </table>
                    </div>
                    <div class="flex items-center justify-center mt-4" v-if="queryLimitReached === false">
                        <button type="button" @click.prevent="loadMoreProducts" class="transition ease-in-out duration-300 inline-flex items-center px-3 py-2 border border-gray-300 shadow-sm text-sm leading-4 font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500">Załaduj więcej..</button>
                    </div>    
                    </section>
                </div>

                <!-- Right column -->
                <div class="grid grid-cols-1  overflow-x-auto gap-4 sticky top-10">
                    <section aria-labelledby="section-2-title">
                    <h2 class="sr-only" id="section-2-title">Section title</h2>
                    <div class="overflow-hidden rounded-lg bg-white shadow">
                        <!-- Your content -->
                        <section aria-labelledby="section-2-title">
                        <h2 class="sr-only" id="section-2-title">Variants</h2>
                         <div class="pt-2 pb-6 px-6">
                            <div class="flex items-center justify-between">
                                <h3 class="text-xl text-gray-700 leading-6 font-semibold py-3">Produkty do zamówienia</h3>
                            </div>
                            <ul style="max-height: 60vh" class="overflow-scroll overflow-x-hidden">
                                <li class="text-lg p-2 border-b" v-for="manufacturer in Object.entries(productOrdersCart)" :key="manufacturer[0]">
                                    <div class="flex justify-between">
                                        <div class="flex gap-x-2">
                                            <p class=" text-gray-600 truncate">{{ findManufacturerById(manufacturer[0]) }}</p>
                                           <button @click="toggleDescriptionModal(manufacturer[0])">
                                                <AnnotationIcon class="w-5 h-5 text-gray-500" />
                                            </button>
                                        </div>
                                        <button @click="toggleManufacturerVariants(manufacturer[0])">
                                            <ChevronDownIcon class="w-5 h-5 transition-all duration-200 transform text-gray-500" :class="[manufacturersToggleList[manufacturer[0]] ? 'rotate-180' : '']"/>
                                        </button>
                                    </div>
                                    <ul v-if="!manufacturersToggleList[manufacturer[0]]" class="pl-2">
                                        <li v-for="variant in Object.values(manufacturer[1])" :key="variant.id" class="relative py-2">
                                            <div class="mx-auto flex max-w-4xl justify-between gap-x-6">
                                                <div class="flex gap-x-4">
                                                    <img class="h-10 w-10 flex-none rounded-full" :src="variant.item.image.url.length>0 ? variant.item.image.url : `https://api.dicebear.com/7.x/initials/svg?seed=${`Product${variant.item.id}`}`" alt="" />
                                                    <div class="min-w-0 flex-auto">
                                                        <p v-tooltip="`${variant.item.name}`" class="text-sm font-semibold leading-6 text-gray-900">
                                                            {{ variant.item.name.slice(0, 25) }} {{ variant.item.name.length > 25 ? '...' : ''}}
                                                        </p>
                                                        <p class="flex text-sm leading-5 text-gray-500">
                                                            {{variant.orderQuantity}} szt.
                                                        </p>
                                                    </div>
                                                </div>
                                                <div class="flex items-center gap-x-4 relative">
                                                    <Popper placement="left" arrow>
                                                        <button class="m-0"><InformationCircleIcon class="w-6 text-gray-300 hover:text-gray-400 transition-all duration-200 cursor-pointer"/></button>
                                                        <template #content> 
                                                            <div class="bg-gray-700 text-white p-2 bg-opacity-75 rounded-lg border max-h-96 overflow-x-auto z-50">
                                                                <ul class="gap-y-1 flex flex-col z-50" v-if="variant.orderIds">
                                                                    <li class="text-sm flex justify-between" v-for="order in Object.entries(variant.orderIds)" :key="order[0]">
                                                                        <p v-if="order[0] !== 'directly'">{{ order[1] }} szt. z zamówienia <a class="text-blue-400 transition-all duration-200 hover:text-blue-300 ml-1" :href="`/dashboard/orders/add?edit=${order[0]}`" target="_blank">{{ order[0] }}</a></p>    
                                                                        <p v-else>{{ order[1] }} szt. <span class="text-blue-400 ml-1">Bezpośrednio</span></p>    
                                                                        <div class="flex gap-x-0.5" v-if="order[0] === 'directly'">
                                                                            <MinusCircleIcon @click="decrementVariant(variant, manufacturer[0])" class="w-5 h-5 transition-all duration-200 text-gray-400 cursor-pointer hover:text-red-400"/>
                                                                            <PlusCircleIcon @click="incrementVariant(variant, manufacturer[0])" class="w-5 h-5 transition-all duration-200 text-gray-400 cursor-pointer hover:text-green-300"/>
                                                                            <TrashIcon @click="removeVariant(variant, manufacturer[0], order)" class="w-5 h-5 transition-all duration-200 text-gray-400 cursor-pointer hover:text-red-400"/>
                                                                        </div>
                                                                    </li>
                                                                </ul>
                                                            </div>
                                                        </template>
                                                    </Popper>
                                                </div>
                                            </div>
                                        </li>
                                    </ul>
                                </li>
                            </ul>
                            <EmptyState class="my-3" v-if="Object.keys(productOrdersCart).length === 0" />
                            <div class="flex justify-end mt-4">
                                <button v-if="Object.keys(productOrdersCart).length > 0" @click.prevent="placeOrder()" type="button" class="tranistion-all duration-300 inline-flex items-center gap-x-1.5 rounded-md bg-indigo-600 px-2.5 py-1.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">
                                    <MailOpenIcon class="-ml-0.5 h-5 w-5" aria-hidden="true" />
                                    Wyślij zamówienie
                                </button>
                            </div>
                        </div>
                        </section>
                    </div>
                    </section>
                </div>
            </div>
        </div>
    </div>
    </Transition>
    <Transition name="fade">
    <div v-if="this.tabs[0].current">
        <div class="mb-4 -mt-4">
            <div class="flex">
                <div class="flex mr-4">
                <label for="search" class="sr-only">Szukaj</label>
                <div class="relative">
                    <div class="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                        <SearchIcon class="h-5 w-5 text-gray-400" aria-hidden="true" />
                    </div>
                    <input :disabled="this.searcher.locked" v-model="searcher.phrase" id="search" name="search" :class="['block w-full rounded-md border border-gray-300 bg-white py-2 pl-10 pr-3 text-sm placeholder-gray-500 focus:border-blue-500 focus:text-gray-900 focus:placeholder-gray-400 focus:outline-none focus:ring-1 focus:ring-blue-500 sm:text-sm', this.searcher.locked ? 'opacity-50' : '']" placeholder="Wyszukaj" type="search" />
                </div>
                <button :disabled="this.searcher.locked" type="button" @click.prevent="search" :class="['transition ease-in-out duration-300 ml-2 inline-flex items-center rounded border border-transparent bg-blue-100 px-2.5 py-1.5 text-xs font-medium text-blue-700', this.searcher.locked || this.searcher.phrase.length === 0 ? 'opacity-50 cursor-default' : 'hover:bg-blue-200 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2']">Szukaj</button>
                </div>
            </div>
            <div class="mt-4" v-if="searcher.found.length > 0">
                <span class="inline-flex items-center py-0.5 pl-2 pr-0.5 rounded-full text-xs font-medium bg-blue-100 text-blue-700">
                Wynik wyszukiwania dla frazy: "{{searcher.lastCall.phrase}}" (Znaleziono: {{searcher.found.length}})
                <button @click.prevent="resetSearch"  type="button" class="transition ease-in-out duration-300 flex-shrink-0 ml-0.5 h-4 w-4 rounded-full inline-flex items-center justify-center text-blue-400 hover:bg-blue-200 hover:text-blue-500 focus:outline-none focus:bg-blue-500 focus:text-white">
                    <svg class="h-2 w-2" stroke="currentColor" fill="none" viewBox="0 0 8 8">
                        <path stroke-linecap="round" stroke-width="1.5" d="M1 1l6 6m0-6L1 7" />
                    </svg>
                </button>
                </span>   
            </div>
        </div>

        <div class="mx-auto max-w-3xl lg:max-w-7xl">
            <h1 class="sr-only">Page title</h1>
            <!-- Main 3 column grid -->
            <div class="grid grid-cols-1 items-start gap-4 lg:grid-cols-3 lg:gap-8">
                <!-- Left column -->
                <div class="grid grid-cols-1 gap-4 lg:col-span-2">
                    <section aria-labelledby="section-1-title">
                    <h2 class="sr-only" id="section-1-title">Section title</h2>
                    <div class="overflow-hidden rounded-lg bg-white shadow">
                        <!-- Your content -->
                        <table class="min-w-full divide-y divide-gray-300">
                            <thead class="bg-gray-50">
                            <tr>
                                <th scope="col" class="py-3.5 pl-4 pr-3 text-left text-sm uppercase font-semibold text-gray-500 sm:pl-6">Produkt</th>
                                <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-500">EAN / SKU</th>
                                <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold uppercase text-gray-500">Atrybuty</th>
                                <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold uppercase text-gray-500">Warianty</th>
                                <th scope="col" class="pr-3 py-3.5 text-left text-sm font-semibold text-gray-500">
                                    <span class="sr-only">Informacje</span>
                                </th>
                            </tr>
                            </thead>
                            <tbody class="divide-y divide-gray-200" v-for="(product,index) in searcher.found.length > 0 ? searcher.found : products" :key="index">
                            <tr>
                            <td class="whitespace-nowrap py-4 text-sm sm:pl-6">
                            <div class="flex items-center">
                                    <div class="h-10 w-10 flex-shrink-0">
                                        <img class="h-10 w-10 rounded-md object-contain" :src="product.image.url.length>0 ? product.image.url : `https://api.dicebear.com/7.x/initials/svg?seed=${`Product${product.id}`}`" :alt="product.name" />
                                    </div>
                                    <div class="ml-4">
                                        <div class="font-medium text-gray-900" v-tooltip="`${product.name}`">{{product.name.slice(0, 20) + `${product.name.length > 20 ? '...' : ''}`}}
                                        <span class="ml-1 transition ease-in-out duration-300 text-xs text-blue-300 hover:text-blue-500 select-none cursor-pointer" @click.prevent="copyToClipboard(product.id)">Kopiuj ID</span>
                                        </div>
                                        <div class="text-gray-500">
                                        <span class="text-xs" v-if="product.category.name.length>0">
                                                {{product.category.name}}
                                        <span class="text-xs opacity-60">(ID kategorii:{{product.category.id}})</span>
                                        </span>
                                        <span class="text-xs" v-if="product.category.name.length===0">Brak kategorii</span>
                                        </div>
                                    </div>
                            </div>
                            </td>
                            <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500 select-none">
                                <div class="text-gray-500">
                                    <span class="text-xs" v-if="product.ean.length>0">
                                        <span class="text-gray-700 font-medium">EAN: </span>{{product.ean}}</span><span class="text-xs font-medium opacity-60" v-if="product.ean.length===0">Brak EAN</span><span class="transition ease-in-out duration-300 ml-2 text-xs text-blue-300 hover:text-blue-600 select-none cursor-pointer" v-if="product.ean.length>0" @click.prevent="copyToClipboard(product.ean)">Kopiuj</span></div>
                                <div class="text-gray-500"><span class="text-xs" v-if="product.sku.length>0"><span class="text-gray-700 font-medium">SKU: </span>{{product.sku}}</span><span class="text-xs font-medium opacity-60" v-if="product.sku.length===0">Brak SKU</span><span class="transition ease-in-out duration-300 ml-2 text-xs text-blue-300 hover:text-blue-600 select-none cursor-pointer" v-if="product.sku.length>0" @click.prevent="copyToClipboard(product.sku)">Kopiuj</span></div>
                            </td>
                            <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500 select-none">
                                <div>
                                    <span v-for="value in product.attribs.list.slice(0, 1)" :key="value.id" @click="showModal(product)"  class="transition ease-in-out duration-300 select-none w-fit inline-flex items-center px-2.5 py-0.5 rounded-md text-xs font-medium bg-gray-100 text-gray-600 cursor-pointer hover:bg-blue-100 hover:text-blue-400"> {{value.parent.name}}: {{value.name}} </span>
                                    <span class="transition ease-in-out duration-300 text-xs px-2.5 py-0.5 text-blue-300 hover:text-blue-500 cursor-pointer" v-if="product.attribs.list.length-1 > 0" @click="showModal(product)">+ {{product.attribs.list.length-1}} więcej</span>
                                    <span class="text-xs" v-if="product.attribs.list.length === 0">Brak</span>
                                </div>
                            </td>
                            <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-700 select-none">
                                <div class="w-28">
                                    <span class="w-full justify-center inline-flex items-center rounded-full bg-blue-50 px-2 py-1 text-xs font-medium text-blue-700 ring-1 ring-inset ring-blue-700/10" v-if="Object.keys(product.variants).length > 0">{{Object.keys(product.variants).length}} {{Object.keys(product.variants).length === 1 ? "wariant" : Object.keys(product.variants).length > 1 && Object.keys(product.variants).length < 5 ? "warianty" : "wariantów"}}</span>
                                    <span class="text-xs" v-if="Object.keys(product.variants).length === 0">Brak wariantów</span>
                                </div>
                            </td>
                            <td class="whitespace-nowrap py-4 pr-5 text-xs cursor-pointer">
                                <div v-auto-animate class="flex h-6 items-center text-gray-500" v-tooltip="`${Object.keys(product.variants).length > 0 ? ' Wyświetl szczegóły produktu' : 'Utwórz nowy wariant produktu.'}`" @click="Object.keys(product.variants).length > 0 ? openAdditionalInformation(index,product) : this.$router.push(`/dashboard/products/list/editProduct?id=${product.id}`)">
                                    <EyeIcon class="h-6 w-6" v-if="Object.keys(product.variants).length > 0 && !this.toggleInformation[index].open" />  
                                    <EyeOffIcon class="h-6 w-6" v-if="this.toggleInformation[index].open" />
                                </div>
                            </td>
                            </tr>
                            <td colspan="12" v-if="this.toggleInformation[index].open" class="bg-gray-300 border-t border-gray-300 w-full">
                                <table class="min-w-full divide-y divide-gray-300">
                                    <thead class="bg-gray-50 w-full">
                                        <tr>
                                        <th scope="col" class="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6 uppercase">Warianty</th>
                                        <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 uppercase">Ilość Sztuk</th>
                                        <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"></th>
                                        </tr>
                                        <tr v-for="(variant,index) in createPositionsList(product)" :key="index">
                                            <td class="border-t border-gray-300 whitespace-nowrap py-4 pl-4 pr-3 text-sm sm:pl-6">
                                                <div class="flex items-center">
                                                    <div class="h-10 w-10 flex-shrink-0">
                                                        <img class="h-10 w-10 rounded-md object-contain" :src="variant.item.image.url.length>0 ? variant.item.image.url : `https://api.dicebear.com/7.x/initials/svg?seed=${`Product${variant.item.id}`}`" :alt="variant.item.id" />
                                                    </div>
                                                    <div class="ml-4">
                                                        <div class="font-medium text-gray-900">
                                                            {{variant.item.name}}
                                                            <span class="transition ease-in-out duration-300 text-xs text-blue-300 hover:text-blue-500 select-none cursor-pointer" @click.prevent="copyToClipboard(variant.item.id)">Kopiuj ID</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </td>
                                            <td class="border-t border-gray-300 whitespace-nowrap px-3 py-4 text-sm text-gray-500 select-none">
                                                <div :class="['text-gray-400 font-medium', variant.item.quantity < 10 ? 'text-red-700 font-medium':'']"><span class="text-xs" ><span class="text-gray-700 font-medium ">Ilość: </span>{{variant.item.quantity}}</span></div>
                                            </td>
                                            <td class="border-t border-gray-300 whitespace-nowrap px-3 py-4 text-sm text-gray-500 select-none">
                                                <button @click="addVariantToList(variant, 'custom')" type="button" class="transition-all duration-300 rounded bg-white px-2 py-1 text-xs font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-200">Dodaj</button>
                                            </td>
                                        </tr>
                                    </thead>
                                </table>
                            </td>
                            </tbody>
                        </table>
                    </div>
                    <div class="py-10">
                        <LoadingSpinnerHub v-if="loading" />
                        <div v-if="products.length > 0 && searcher.found.length === 0 && !loading && !queryLimitReached" class="flex justify-center mt-10">
                            <InfiniteLoading @infinite="loadMoreProducts" />
                        </div>
                    </div>
                    </section>
                </div>
                <!-- Right column -->
                <div class="grid grid-cols-1  overflow-x-auto gap-4 sticky top-10">
                    <section aria-labelledby="section-2-title">
                    <h2 class="sr-only" id="section-2-title">Section title</h2>
                    <div class="overflow-hidden rounded-lg bg-white shadow">
                        <!-- Your content -->
                        <section aria-labelledby="section-2-title">
                        <h2 class="sr-only" id="section-2-title">Variants</h2>
                        <div class="pt-2 pb-6 px-6">
                            <div class="flex items-center justify-between pt-3">
                                <h3 class="text-xl text-gray-700 leading-6 font-semibold">{{editableList.active ? 'Edytuj listę PZ / WZ:' : 'Zamówione produkty:'}}</h3>
                            </div>
                            <ul style="max-height: 60vh" class="overflow-scroll overflow-x-hidden">
                            <div v-if="editableList.active" class="py-2">
                                <label for="email" class="block text-sm leading-6 text-gray-500 uppercase font-semibold">Tytuł listy</label>
                                <input v-model="currentlyCustomized.title" type="text" name="listTitle" id="listTitle" class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" placeholder="np.: XXX" />
                            </div>
                            <li class="text-lg border-b" v-for="(manufacturer, index) in Object.entries(productOrdersCart)" :key="manufacturer[0]">
                                <div class="flex justify-between items-center" :class="index === 0 ? '' : 'mt-3'">
                                    <div class="flex gap-x-2">
                                    <p class=" text-gray-600 truncate uppercase font-semibold">Produkt {{index+1}}</p>
                                    </div>
                                    <button @click="toggleManufacturerVariants(manufacturer[0])">
                                    <ChevronDownIcon class="mr-2 w-6 h-6 transition-all duration-200 transform text-gray-500" :class="[manufacturersToggleList[manufacturer[0]] ? 'rotate-180' : '']"/>
                                    </button>
                                </div>
                                <ul v-if="!manufacturersToggleList[manufacturer[0]]" class="pl-2">
                                    <li v-for="variant in Object.values(manufacturer[1])" :key="variant.id" class="relative py-2 mb-2">
                                    <div :class="['mx-auto flex max-w-4xl justify-between gap-x-6']">
                                        <div :class="['flex gap-x-4', editableList && variant.added ? 'opacity-50' : '']">
                                            <img class="h-10 w-10 flex-none rounded-full" :src="variant.item.image.url.length>0 ? variant.item.image.url : `https://api.dicebear.com/7.x/initials/svg?seed=${`${variant.item.name}`}`" alt="" />
                                            <div class="min-w-0 flex-auto">
                                                <p v-tooltip="`${variant.item.name}`" class="text-sm font-semibold leading-6 text-gray-900">
                                                    {{ variant.item.name.slice(0, 25) }} {{ variant.item.name.length > 25 ? '...' : ''}}
                                                </p>
                                                <p class="flex text-sm leading-5 text-gray-500">
                                                    {{variant.orderQuantity}} szt.
                                                </p>
                                            </div>
                                        </div>
                                        <TrashIcon @click="decrementVariant(variant, manufacturer[0], 'list', variant.index)" class="mr-1 w-8 text-gray-300 hover:text-gray-400 transition-all duration-200 cursor-pointer"/>
                                    </div>
                                    </li>
                                </ul>
                            </li>
                            </ul>
                            <EmptyState class="my-3" v-if="Object.keys(productOrdersCart).length === 0" />
                            <Transition name="fade">
                            <div class="flex justify-end mt-4" v-if="Object.keys(productOrdersCart).length > 0 && !editableList.active">
                                <button @click.prevent="toggleCustomOrdersModal" type="button" class="inline-flex items-center gap-x-2 rounded-md bg-indigo-100 px-3.5 py-2.5 text-sm font-semibold text-indigo-600 shadow-sm hover:bg-indigo-200 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 transition-all duration-300">
                                    <ViewGridAddIcon class="-ml-0.5 h-5 w-5" aria-hidden="true" />
                                    Dodaj produkty
                                </button>
                            </div>
                            </Transition>
                            <Transition name="fade">
                            <div class="flex justify-between mt-4" v-if="Object.keys(productOrdersCart).length > 0 && editableList.active">
                                <button @click.prevent="cancelEditList" type="button" class="transition-all duration-300 inline-flex items-center gap-x-1.5 rounded-md bg-gray-200 px-3 py-2 text-sm font-semibold text-gray-500 shadow-sm hover:bg-gray-300 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">
                                    Anuluj
                                </button>
                                <button @click.prevent="editList" type="button" class="transition-all duration-300 inline-flex items-center gap-x-1.5 rounded-md bg-indigo-200 px-3 py-2 text-sm font-semibold text-indigo-500 shadow-sm hover:bg-indigo-300 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">
                                    Zaktualizuj listę
                                    <CheckCircleIcon class="-mr-0.5 h-5 w-5" aria-hidden="true" />
                                </button>
                            </div>
                            </Transition>
                        </div>
                        </section>
                    </div>
                    </section>
                </div>
            </div>
        </div>
    </div>
    </Transition>
    <Transition name="fade">
    <div v-if="this.tabs[1].current">
        <div class="mt-4 flex flex-col">
            <div class="overflow-x-auto">
                <div class="inline-block min-w-full align-middle">
                    <div class="overflow-hidden shadow border rounded-lg">
                        <table class="min-w-full divide-y divide-gray-300">
                            <thead class="bg-gray-50">
                                <tr>
                                    <th scope="col" class="whitespace-nowrap py-3.5 pl-4 pr-3 sm:pl-6 text-left text-xs font-semibold text-gray-600 uppercase">Data utworzenia</th>
                                    <th scope="col" class="px-3 py-3.5 text-left text-xs font-semibold text-gray-600 uppercase">Lista</th>
                                    <th scope="col" class="px-3 py-3.5 text-left text-xs font-semibold text-gray-600 uppercase">
                                        <div class="w-36 truncate select-none" v-tooltip="'Data pierwszego przyjęcia'">
                                            <span class="truncate">Data pierwszego przyjęcia</span>
                                        </div>
                                    </th>
                                    <th scope="col" class="px-3 py-3.5 text-left text-xs font-semibold text-gray-600 uppercase">Ilość przyjętych</th>
                                    <th scope="col" class="px-3 py-3.5 text-left text-xs font-semibold text-gray-600 uppercase">Utworzone przez</th>
                                    <th scope="col" class="relative py-3.5 pl-3 pr-4 sm:pr-6">
                                        <span class="sr-only">Information</span>
                                    </th>
                                    <th scope="col" class="relative py-3.5 pl-3 pr-4 sm:pr-6">
                                        <span class="sr-only">Information</span>
                                    </th>
                                    <th scope="col" class="relative py-3.5 pl-3 pr-4 sm:pr-6">
                                        <span class="sr-only">Delete</span>
                                    </th>
                                </tr>
                            </thead>
                            <tbody v-for="(list, index) in lists" :key="list.id" class="divide-y divide-gray-200 bg-white">
                                <tr>
                                    <td class="whitespace-nowrap w-44 px-3 py-4 pl-4 sm:pl-6 text-xs ">
                                        <span v-if="list.meta.created != null" class="font-semibold text-gray-600">
                                            {{moment.unix(list.meta.created.unix).format('LLL')}}
                                        </span>
                                        <span v-if="list.meta.created == null" class="opacity-40 uppercase font-medium select-none">
                                            Nie wskazano
                                        </span>
                                    </td>
                                    <td class="whitespace-nowrap px-3 pr-10 py-4 text-xs text-gray-900 font-semibold">
                                        <div class="inline-flex">
                                            <span v-if="list.title.length>0">{{ list.title }}</span>
                                            <span v-if="list.title.length === 0">--</span>
                                        </div>
                                    </td>
                                    <td class="px-3 py-4 text-xs text-gray-900 font-semibold">
                                        <span v-if="list.meta.accepted != null" class="font-semibold text-gray-600">
                                            {{moment.unix(list.meta.accepted.unix).format('LLL')}}
                                        </span>
                                        <span v-if="list.meta.accepted == null" class="opacity-40 uppercase font-medium select-none">
                                            BRAK
                                        </span>
                                    </td>
                                    <td class="px-3 py-4 text-xs text-gray-900 font-semibold">
                                        <span class="font-semibold text-gray-600">
                                            {{list.printed + ' / ' + list.quantity}}
                                        </span>
                                    </td>
                                    <td class="px-3 py-4 text-xs text-gray-900 font-semibold">
                                        <span v-if="list.meta.created != null" class="font-semibold text-gray-600">
                                            {{list.meta.created.by}}
                                        </span>
                                        <span v-if="list.meta.created == null" class="opacity-40 uppercase font-medium select-none">
                                            BRAK
                                        </span>
                                    </td>
                                    <td class="px-3 py-4 pl-4 text-xs">
                                       <span v-if="list.accepted" v-tooltip="`PRZEZ: ${list.meta.accepted.by != null ? list.meta.accepted.by : '--'}`" class="font-bold uppercase text-green-500">Zaakceptowano</span>
                                       <span v-else class="font-bold uppercase text-red-500">Niezaakceptowana</span>
                                    </td>
                                    <td class="px-3 py-4 pl-4 text-xs">
                                        <ChevronDownIcon v-if="!currentlyCustomized.edited" v-tooltip="`Wyświetl listę`" @click="openAdditionalInformationList(index)" :class="[this.toggleInformationList[index].open ? 'transform rotate-180' : '', 'text-gray-600 hover:text-gray-400 transition-all duration-300 cursor-pointer outline-none h-6 w-6']" aria-hidden="true" />
                                    </td>
                                    <td :class="['py-4 px-3 lg:pr-3 lg:pl-6 text-xs', !list.accepted ? 'border-l' : '']" v-auto-animate>
                                        <div v-if="!list.accepted">
                                            <PencilAltIcon v-if="!currentlyCustomized.edited && (currentlyCustomized.id !== list.id)" v-tooltip="`Edytuj listę (${list.title})`" @click="toggleListsModal(list, index)" class="text-gray-600 hover:text-gray-400 transition-all duration-300 cursor-pointer outline-none h-6 w-6" />
                                            <XIcon v-if="(currentlyCustomized.id === list.id)" v-tooltip="`Anuluj edytowanie listy`" @click="toggleListsModal(list, index)" class="text-white hover:text-gray-400 bg-red-500 rounded-md transition-all duration-300 cursor-pointer outline-none h-6 w-6" />
                                        </div>
                                    </td>
                                </tr>
                                <Transition name="fade">
                                <td colspan="12" v-if="this.toggleInformationList[index].open" class="toggled bg-gray-50 border-t border-gray-300 px-6">
                                    <div v-if="currentlyCustomized.edited && (currentlyCustomized.id === list.id)" :class="['mt-5 flex', list.accepted ? 'justify-end' : 'justify-between']">
                                        <button v-if="!list.accepted" @click="acceptList(list.id)" type="button" class="rounded-md bg-green-200 px-3 py-2 text-sm font-semibold text-green-600 shadow-sm hover:bg-green-300 transition-all duration-300">
                                            Akceptuj listę
                                        </button>
                                        <div class="flex gap-2">
                                            <button @click="addMoreToModal(list.id)" type="button" class="rounded-md bg-indigo-200 px-3 py-2 text-sm font-semibold text-indigo-500 shadow-sm hover:bg-indigo-300 transition-all duration-300">
                                                Edytuj listę
                                            </button>
                                            <button @click="toggleDeleteListModal(list.id)" type="button" class="rounded-md bg-gray-200 px-3 py-2 text-sm font-semibold text-gray-600 shadow-sm hover:bg-red-500 hover:text-white transition-all duration-300">
                                                Usuń listę
                                            </button>
                                        </div>
                                    </div>
                                    <div class="py-5">
                                        <div class="flex flex-col">
                                        <!-- <span class="mr-1 text-xs text-gray-500 uppercase font-semibold">Informacje o liście:</span> -->
                                            <table class="w-full text-gray-500 mt-2" v-if="this.toggleInformationList[index].open">
                                            <caption class="sr-only">
                                                Lists
                                            </caption>
                                            <thead class="sr-only text-left text-sm text-gray-500 sm:not-sr-only">
                                                <tr>
                                                    <th scope="col" class="py-2 pr-8 font-semibold uppercase text-xs">Produkt</th>
                                                    <th scope="col" class="hidden w-24 py-2 pr-8 font-semibold sm:table-cell uppercase text-xs">Wydrukowano?</th>
                                                    <th scope="col" class="hidden py-2 pr-8 font-semibold text-xs uppercase sm:table-cell w-24 whitespace-nowrap">Przyjęte</th>
                                                    <th scope="col" class="hidden py-2 pr-8 font-semibold text-xs uppercase sm:table-cell">Lokalizacja</th>
                                                    <th scope="col" class="hidden py-2 pr-8 font-semibold text-xs uppercase sm:table-cell">Przez</th>
                                                    <!-- <th v-if="currentlyCustomized.edited && (currentlyCustomized.id === list.id)" scope="col" class="hidden py-2 pr-8 font-normal sm:table-cell text-center">Usuń produkt</th> -->
                                                </tr>
                                            </thead>
                                            <tbody class="divide-y divide-gray-200 border-b border-gray-200 text-sm sm:border-t">
                                                <template v-for="(product, ind) in Object.values(list.products)"  :key="ind">
                                                <tr v-for="(prod, indx) in Object.values(product)" :key="indx">
                                                    <td class="py-1">
                                                        <div class="flex items-center">
                                                            <img :src="prod.item.image.url.length>0 ? prod.item.image.url : `https://api.dicebear.com/7.x/initials/svg?seed=${`Product${prod.item.id}`}`" :alt="prod.item.image.url.length>0 ? prod.item.image.url : `https://api.dicebear.com/7.x/initials/svg?seed=${`Product${prod.item.id}`}`" class="mr-6 h-11 w-11  object-cover object-center mix-blend-multiply rounded-full border border-gray-400" />
                                                            <div>
                                                                <div v-tooltip="`${prod.item.name}`" class="w-48 truncate">
                                                                    <span class="font-medium text-gray-900 text-sm">{{ prod.item.name }}</span>
                                                                </div>
                                                                <div class="mt-1 sm:hidden">
                                                                    <span :class="['inline-flex items-center rounded-full px-2 py-1 text-sm font-medium', prod.ids != null ? 'text-blue-700 bg-blue-100' : 'text-red-700 bg-red-100' ]">{{ prod.ids != null ? 'TAK' : 'NIE'}}</span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td class="hidden sm:table-cell py-2">
                                                        <span :class="['inline-flex items-center rounded-full px-3 py-1 text-sm font-medium', prod.ids != null ? 'text-blue-700 bg-blue-100' : 'text-red-700 bg-red-100']">{{ prod.ids != null ? 'TAK' : 'NIE'}}</span>
                                                    </td>
                                                    <td v-if="prod.ids != null" class="hidden sm:table-cell py-2">
                                                        <span :class="[prod.countAccepted > 0 ? 'text-indigo-500' : 'text-red-500', prod.countAccepted === prod.item.amountOfPackages ? 'text-green-500' : '']">{{ prod.countAccepted }}</span> / {{prod.item.amountOfPackages}}
                                                    </td>
                                                    <td v-else class="hidden m:table-cell py-2">---</td>
                                                    <td class="hidden sm:table-cell py-1">
                                                        <div v-if="prod.locations != null">
                                                            <div v-for="loc, index in Object.keys(prod.locations)" :key="index" class="flex flex-col gap-1">
                                                                <div class="text-xs">
                                                                    <span class="text-gray-500 uppercase text-xs">Paczka:</span> (<span class="font-semibold">{{prod.locations[loc].part}}</span>),
                                                                    <span class="text-gray-500 uppercase text-xs">Magazyn:</span> (<span class="font-semibold">{{prod.locations[loc].warehouse.name}}</span>),
                                                                    <span class="text-gray-500 uppercase text-xs">Segment:</span> (<span class="font-semibold">{{prod.locations[loc].segment.name}}</span>)
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div v-else>
                                                            ---
                                                        </div>
                                                    </td>
                                                    <td class="hidden sm:table-cell py-2">
                                                        <div v-if="prod.locations != null">
                                                            <div v-for="loc, index in Object.keys(prod.locations)" :key="index" class="flex flex-col gap-1">
                                                                <span v-if="prod.locations[loc].addedBy != null" class="font-semibold text-xs uppercase text-gray-500">{{prod.locations[loc].addedBy.user}}</span>
                                                                <span v-else class="font-semibold text-xs uppercase text-gray-500">---</span>
                                                            </div>
                                                        </div>
                                                        <div v-else>
                                                            ---
                                                        </div>
                                                    </td>
                                                    <!-- <td v-if="currentlyCustomized.edited && (currentlyCustomized.id === list.id)" class="hidden py-2.5 pr-8 sm:table-cell">
                                                        <div class="flex justify-center">
                                                            <TrashIcon @click.prevent="deleteProductFromList(list, prod, index)" :class="[blockButton.delete ? 'opacity-30 pointer-events-none' : '', 'w-6 h-6 text-gray-500 hover:text-red-600 transition-all duration-300 cursor-pointer']" />
                                                        </div>
                                                    </td> -->
                                                </tr>
                                                </template>
                                            </tbody>
                                            </table>
                                        </div>
                        
                                        <div class="flex justify-between mt-4 opacity-50">
                                            <span class="text-gray-500 text-xs select-none">Data ostaniej modyfikacji: <span class="font-bold select-text">{{list.meta.created.lastModificationDate != null ? moment.unix(list.meta.created.lastModificationDate).format('LLL') : ''}}</span></span>                                                
                                            <span class="text-gray-500 text-xs select-none">ID: <span class="font-bold select-text">{{list.id}}</span></span>                                                
                                        </div>
                                    </div>
                                </td>
                                </Transition>
                            </tbody>
                        </table>
                        <EmptyState v-if="lists.length === 0" />
                    </div>
                </div>
            </div>
        </div>
        <div class="mt-4 flex justify-center">
            <button v-if="!queryLimitListsReached" @click.prevent="loadMoreLists" type="button" class="rounded-md bg-indigo-200 px-3.5 py-2.5 text-sm font-semibold text-indigo-600 shadow-sm hover:bg-indigo-300 transition-all duration-300">Załaduj więcej</button>
        </div>
        <!-- koniec tego taba -->
    </div>
    </Transition>
    <ModalSelectManufacturer v-if="modalSelectManufacturer.open" :product="modalSelectManufacturer.product" :allManufacturers="allManufacturers" :order="modalSelectManufacturer.order" @addManufacturer="addManufacturer" @close="modalSelectManufacturer.open = false"/>
    <NewListModal v-if="modalCustomOrders.open" @closeModal="toggleCustomOrdersModal" @accept="addCustomOrders" :modalData="modalCustomOrders.data" buttonColor="indigo" iconColor="indigo" />
    <AlertModal v-if="modalDescritpion.open" @closeModal="toggleDescriptionModal" @accept="addDescription" :modalData="modalDescritpion.data" buttonColor="green" iconColor="green">
        <div class="flex flex-col border-t mt-4 border-gray-200">
            <label for="order-description" class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">Dodatkowa informacja</label>
            <div class="mt-1">
                <textarea v-model="modalDescritpion.description" maxlength="500" id="order-description" rows="3" class="max-w-lg py-2 px-4 shadow-sm block w-full focus:ring-blue-500 focus:border-blue-500 sm:text-sm border border-gray-300 rounded-md" />
            </div>
        </div>
    </AlertModal>
    <AlertListsModal v-if="modalList.open" @closeModal="toggleDeleteListModal" @accept="deleteList" :modalData="modalList.data" buttonColor="red" iconColor="red" />
</template>
<script>
import {db} from "@/firebase/gfbconf.js";
import axios from 'axios';
import moment from 'moment';
import randomstring from 'randomstring';
import { CheckCircleIcon, XIcon, ViewGridAddIcon, HomeIcon, MailOpenIcon, ChevronDownIcon } from '@heroicons/vue/solid'
import { BanIcon, PencilAltIcon, DocumentAddIcon, PlusIcon, SearchIcon,EyeIcon,EyeOffIcon,TrashIcon, MinusCircleIcon, PlusCircleIcon, InformationCircleIcon, AnnotationIcon } from '@heroicons/vue/outline';
import EmptyState from '../components/EmptyState.vue'
import AlertModal from '../components/AlertModal.vue'
import AlertListsModal from '../components/AlertListsModal.vue'
import NewListModal from '../components/NewListModal.vue'
import LoadingSpinnerHub from '../components/LoadingSpinnerHub.vue';
import ModalSelectManufacturer from "../components/ModalSelectManufacturer.vue"
import InfiniteLoading from "v3-infinite-loading";
import "v3-infinite-loading/lib/style.css";

export default {
    components: {
       CheckCircleIcon, XIcon, BanIcon, PencilAltIcon, AlertListsModal, NewListModal, ViewGridAddIcon, DocumentAddIcon, InfiniteLoading, HomeIcon, SearchIcon,  EyeOffIcon, EyeIcon, MailOpenIcon, PlusIcon, AnnotationIcon, ChevronDownIcon, InformationCircleIcon, MinusCircleIcon, PlusCircleIcon, TrashIcon, EmptyState, ModalSelectManufacturer, AlertModal, LoadingSpinnerHub,
    },
    data() {
        return {
            modalDescritpion: {
                open: false,
                data: {
                    headerText: "Dodatkowa informacja",
                    bodyText: "Dodaj dodatkową informację do zamówienia.",
                    acceptButtonText: "Dodaj"
                },
                manufacturerId: null,
                description: ""
            },
            modalList: {
                open: false,
                data: {
                    headerText: "Usuń listę",
                    bodyText: "Czy na pewno chcesz usunąć listę? Po usunięciu listy nie będzie możliwości jej przywrócenia.",
                    acceptButtonText: "Usuń"
                },
                listId: null
            },
            index: 1,
            modalCustomOrders: {
                open: false,
                data: {
                    headerText: "Utwórz zamówienie",
                    bodyText: "Utwórz zamówienie z listy produktów. Wprowadź nazwę zamówienia. Po zatwierdzeniu zostaniesz przeniesiony do listy produktów.", 
                    acceptButtonText: "Dodaj"
                },
            },
            modalSelectManufacturer: {
                open: false,
                variant: null,
                order: null
            },
            variants:[],
            toggleInformation: {},
            toggleInformationList: {},
            loading: false,
            searcher: {
                found: [],
                locked: false,
                phrase: "",
                lastCall: null
            },
            modal: {
                open: false,
                product: null,
                saving: false
            },
            products: [],
            queryLimit: 25,
            dbListener: null,
            dbListenerCart: null,
            dbListenerLists: null,
            dbListenerManufacturer: null,
            lastLoaded: null,
            queryLimitListsReached: false,
            query:null,
            productOrdersCart: {},
            loadedProducts: false,
            manufacturersToggleList: {},
            allManufacturers: [],
            productOrdersDescriptions: {},
            tabs: [
                { name: 'Utwórz listę PZ / WZ', current: false },
                { name: 'Utworzone listy PZ / WZ', current: false },
                { name: 'Zamów u producenta', current: false },
            ],
            openList: false,
            lists: [],
            currentlyCustomized: {
                edited: false,
                id: null,
                title: null,
            },
            blockButton: {
                save: false,
                delete: false
            },
            editableList: {
                id: null,
                active: false
            }
        }
    },
    methods: {
        async acceptList(id)
        {
            try {
                const checkList = await db.collection('Lists').doc(id).get();
                if(!checkList.exists)
                {
                    throw 'Lista nie istnieje.';
                }
                else
                {
                    await db.collection('Lists').doc(id).update({
                        "accepted": true,
                        "meta.accepted.by": `${this.$store.state.userData.name} ${this.$store.state.userData.surname}`,
                      
                    });
                    this.$store.commit('setNotification',{
                        show: true,
                        head: "Akcja zakończona sukcesem!",
                        subheader: 'Zaakceptowano listę.',
                        success: true
                    });
                }
            } catch (error)
            {
                console.log(error);
                this.$store.commit('setNotification',{
                    show: true,
                    head: "Nie udało się zaakceptować listy",
                    subheader: 'Spróbuj ponownie później.',
                    success: false
                });  
            }
        },
        cancelEditList()
        {
            this.productOrdersCart = {};
            this.editableList = {
                id: null,
                active: false
            };
            for(let i=0; i<this.tabs.length; i++)
            {
                this.tabs[i].current = false;
            }
            this.tabs[1].current = true;
        },
        async editList()
        {
            try {
                const res = await axios.post(`${this.$store.state.apiLink}/update/custom-fv-orders`, {
                    products: this.productOrdersCart,
                    id: this.editableList.id,
                    title: this.currentlyCustomized.title
                });
                if(res.data.success) {
                    this.productOrdersCart = {};
                    this.editableList = {
                        id: null,
                        active: false
                    };
                    this.currentlyCustomized = {
                        edited: false,
                        id: null,
                        title: null,
                    };
                    for(let i=0; i<this.tabs.length; i++)
                    {
                        this.tabs[i].current = false;
                    }
                    this.tabs[1].current = true;
                    this.$store.commit('setNotification',{
                        show: true,
                        head: "Akcja zakończona sukcesem!",
                        subheader: `Zaktualizowano listę PZ / WZ.`,
                        success: true
                    }); 
                } else {
                    throw 'Napotkano problem podczas aktualizacji listy PZ / WZ. Spróbuj ponownie później.';
                }
            } catch (error)
            {
                console.log(error);
                this.$store.commit('setNotification',{
                    show: true,
                    head: "Nie udało się zaktualizować listy PZ / WZ",
                    subheader: 'Spróbuj ponownie później.',
                    success: false
                });  
            }
        },
        async addMoreToModal(id)
        {
            try {
                for(let i=0; i<this.tabs.length; i++)
                {
                    this.tabs[i].current = false;
                }
                this.editableList.active = true;
                this.editableList.id = id;
                this.tabs[0].current = true;
                const alrdyAdded = await db.collection('Lists').doc(id).get();
                if(alrdyAdded.exists)
                {
                    const data = alrdyAdded.data();
                    const products = Object.values(data.products);
                    for(let i=0; i<products.length; i++)
                    {
                        const product = products[i];
                        const variants = Object.values(product);
                        for(let y=0; y<variants.length; y++)
                        {
                            variants[y].added = true;
                        }
                    }
                    this.productOrdersCart = data.products;
                }
            } catch (error)
            {
                console.log(error);    
            }
        },
        async saveChangesToList(id, index)
        {
            try {
                if(this.currentlyCustomized.title.trim() === this.lists[index].title.trim())
                {
                    this.blockButton.save = true;
                    return;
                }
                this.$store.commit('setNotification',{
                    show: true,
                    head: 'Akcja w trakcie...',
                    subheader: 'Zmieniam nazwę listy.',
                    success: true
                });
                const res = await axios.post(`${this.$store.state.apiLink}/list/rename`, {
                    id: id,
                    update: this.currentlyCustomized,
                });
                if(res.data.success)
                {
                    this.$store.commit('setNotification',{
                        show: true,
                        head: "Akcja zakończona sukcesem!",
                        subheader: 'Zmieniono nazwę listy.',
                        success: true
                    });
                    setTimeout(() => {
                        this.toggleInformationList[index].open = true
                    }, 500);
                    // setTimeout(() => {
                    //     this.openAdditionalInformationList(index)
                    // }, 1500);
                }
                else
                {
                    throw res.data.log;
                }
            } catch (error)
            {
                console.log(error);   
                this.$store.commit('setNotification',{
                    show: true,
                    head: 'Nie udało się zmienić nazwy listy',
                    subheader: 'Spróbuj ponownie później.',
                    success: false
                }); 
            }
        },
        async deleteProductFromList(list, product, index)
        {
            try {
                this.blockButton.delete = true;
                const res = await axios.post(`${this.$store.state.apiLink}/list/product/delete`, {
                    id: list.id,
                    list: list,
                    product: product
                });
                if(res.data.success)
                {
                    this.$store.commit('setNotification',{
                        show: true,
                        head: "Akcja zakończona sukcesem!",
                        subheader: 'Usunięto produkt z listy.' ,
                        success: true
                    });
                    setTimeout(() => {
                        this.openAdditionalInformationList(index)
                        this.blockButton.delete = false;
                    }, 750);
                }
                else
                {
                    throw res.data.log;
                }
            } catch (error)
            {
                console.log(error);   
                this.blockButton.delete = false;
                this.$store.commit('setNotification',{
                    show: true,
                    head: "Nie udało się usunąć produktu z listy",
                    subheader: 'Spróbuj ponownie później.',
                    success: false
                }); 
            }
        },
        toggleListsModal(list, index)
        {
            if(!this.currentlyCustomized.edited)
            {
                this.currentlyCustomized.edited = true;
                this.currentlyCustomized.id = list.id;
                this.currentlyCustomized.title = list.title;
                this.openAdditionalInformationList(index)
            }
            else 
            {
                this.currentlyCustomized.edited = false
                this.currentlyCustomized.id = null
                this.toggleInformationList[index].open = false;
            };
            if(this.currentlyCustomized.edited && !this.toggleInformationList[index].open)
            {
                this.toggleInformationList[index].open = true;
            }
      
        },
        openAdditionalInformation(indx,product)
        {
            if(this.toggleInformation[indx].open)
            {
                this.toggleInformation[indx].open = false
            }
            else
            {
                for (let i = 0; i < Object.keys(this.toggleInformation).length; i++) {
                    this.toggleInformation[i].open = false
                }
                this.toggleInformation[indx].open = true
            }
        },
        openAdditionalInformationList(indx, product)
        {
            if(this.toggleInformationList[indx].open)
            {
                this.toggleInformationList[indx].open = false
            }
            else
            {
                for (let i = 0; i < Object.keys(this.toggleInformationList).length; i++) {
                    this.toggleInformationList[i].open = false
                }
                this.toggleInformationList[indx].open = true
            }
        },
        async deleteList()
        {
            try {
                if(this.modalList.listId == null) return;
                await db.collection('Lists').doc(this.modalList.listId).delete();
                this.$store.commit('setNotification',{
                    show: true,
                    head: "Akcja zakończona sukcesem!",
                    subheader: `Usunięto listę.`,
                    success: true
                });
                this.modalList = {
                    open: false,
                    data: {
                        headerText: "Usuń listę",
                        bodyText: "Czy na pewno chcesz usunąć listę? Po usunięciu listy nie będzie możliwości jej przywrócenia.",
                        acceptButtonText: "Usuń"
                    },
                    listId: null
                };           
            } catch (error)
            {
                console.log(error);
                this.$store.commit('setNotification',{
                    show: true,
                    head: "Nie udało się usunąć listy",
                    subheader: error,
                    success: false
                });
            }
        },
        getAllLists()
        {
            this.query = null;
            if(this.dbListenerLists !== null){
                this.dbListenerLists()
                this.dbListenerLists = null
            }
            this.query = db.collection("Lists").orderBy('meta.created.unix', 'desc').limit(this.queryLimit)

            this.dbListenerLists = this.query
            .onSnapshot((querySnapshot) => {
                this.lists = [];
                if(querySnapshot.docs.length<this.queryLimit)
                {
                    this.queryLimitListsReached = true;
                }
                else
                {
                    this.queryLimitListsReached = false;
                }
                for(let i = 0; i < querySnapshot.docs.length; i++)
                {
                    let countProducts = 0;
                    let countPrinted = 0;
                    let data = querySnapshot.docs[i].data()
                    let products = Object.entries(data.products);
                    for(let y=0; y<products.length; y++)
                    {
                        let variants = Object.entries(products[y][1]);
                        for(let z=0; z<variants.length; z++)
                        {
                            let variant = variants[z];
                            if(variant[1].accepted == null)
                            {
                                variant[1].accepted = false;
                            }
                            let isAccepted = 0;
                            if(variant[1].ids != null)
                            {
                                const ids = Object.values(variant[1].ids);
                                for(let i=0;i<ids.length; i++)
                                {
                                    if(ids[i] === true)
                                    {
                                        isAccepted++;
                                    };
                                }
                                countPrinted++;
                                variant[1].countAccepted = isAccepted;
                            }
                            countProducts++;
                        }
                    }
                    data.printed = countPrinted;
                    data.quantity = countProducts;
                    data.current = false;
                    this.lists.push(data)
                    this.toggleInformationList[i] = {
                        open: false
                    }
                }
            });
        },
        continueListeningLists()
        {
            this.queryLimit = this.queryLimit + 25;
            this.getAllLists();
        },
        loadMoreLists()
        {
            this.dbListenerLists();
            this.continueListeningLists();      
        },
        async addCustomOrders(val)
        {
            try {
                const res = await axios.post(`${this.$store.state.apiLink}/create/custom-fv-orders`, {
                    products: this.productOrdersCart,
                    name: val,
                    user: this.$store.state.userData
                })
                if(res.data.success) {
                    this.productOrdersCart = {};
                    this.$store.commit('setNotification',{
                        show: true,
                        head: "Akcja zakończona sukcesem!",
                        subheader: `Dodano produkty do listy.`,
                        success: true
                    }); 
                } else {
                    throw 'Napotkano problem z dodaniem produktów do listy. Spróbuj ponownie.';
                }
            } catch (error) {
                console.log(error);
                this.$store.commit('setNotification',{
                    show: true,
                    head: "Nie udało się dodać produktów",
                    subheader: error,
                    success: false
                }); 
            }
        },
        async changeTab(indx)
        {
            this.productOrdersCart = {};
            this.editableList.active = false;
            this.editableList.id = null;
            this.currentlyCustomized = {
                edited: false,
                id: null,
                title: null,
            };
            for(let i=0; i<this.tabs.length; i++)
            {
                this.tabs[i].current = false;
            }
            this.tabs[indx].current = true;
        },
        addDescription() {
            this.productOrdersDescriptions[this.modalDescritpion.manufacturerId] = this.modalDescritpion.description
        },
        toggleDescriptionModal(manufacturerId) {
            if(this.modalDescritpion.open) {
                this.modalDescritpion.manufacturerId = null
                this.modalDescritpion.description = ""
                this.modalDescritpion.open = false
            } else {
                this.modalDescritpion.description = this.productOrdersDescriptions[manufacturerId] || ""
                this.modalDescritpion.manufacturerId = manufacturerId
                this.modalDescritpion.open = true
            }
        },
        toggleDeleteListModal(listId) {
            if(this.modalList.open) {
                this.modalList.listId = null
                this.modalList.description = ""
                this.modalList.open = false
            } else {
                this.modalList.listId = listId
                this.modalList.open = true
            }
        },
        toggleCustomOrdersModal() {
           if(this.modalCustomOrders.open) {
                this.modalCustomOrders.open = false
            } else {
                this.modalCustomOrders.open = true
            }
        },
        findManufacturerById(manufacturerId) {
            if(!this.allManufacturers.length) {
                return manufacturerId
            }
            return this.allManufacturers.find(el => el.ref.id === manufacturerId).name
        },
        async getAllManufacturers() {
            try {
                this.allManufacturers = []
                this.dbListenerManufacturer = await db.collection("Manufacturers").onSnapshot(querySnapshot => {
                    this.allManufacturers = []
                    querySnapshot.forEach(doc => {
                        const manufacturer = {
                            name: doc.data().name,
                            ref: doc.ref,
                            id: doc.data().id
                        }
                    this.allManufacturers.push(manufacturer)
                    })
                })
                
            } catch (error) {
                console.log(error);
            }
        },
         async addManufacturer(body) {
            try {
                const res = await axios.post(`${this.$store.state.apiLink}/products/manufacturer/replace`, body)
                if(res.data.success) {
                    this.$store.commit('setNotification',{
                        show: true,
                        head: "Zaktualizowano producenta!",
                        subheader: ``,
                        success: true
                    }); 
                } else {
                    this.$store.commit('setNotification',{
                        show: true,
                        head: "Nie udało się zaktualizować producenta!",
                        subheader: ``,
                        success: true
                    }); 
                }
            } catch (error) {
                console.log(error);
                this.$store.commit('setNotification',{
                    show: true,
                    head: "Nie udało się zaktualizować producenta!",
                    subheader: ``,
                    success: true
                }); 
            }
        },
         async openSelectManufacturerModal(prod) {
            try {
                //get variant's product
                const product = await db.collection("Products").doc(prod.id).get()
                if(product.exists) {
                    this.modalSelectManufacturer.product = product.data()
                } else {
                    return console.log("Can't find product")
                }
                this.modalSelectManufacturer.open = true
            } catch (error) {
                console.log(error);
            }
        },
        checkManufacturer(product) {
            //in order from orders this functionality is in 'checkExist' function
            if(typeof product.manufacturer === "string") {
                return false
            }
            return true

        },
        createToggleList() {
            Object.keys(this.productOrdersCart).forEach(manufacturer => {
                this.manufacturersToggleList[manufacturer] = this.manufacturersToggleList[manufacturer] || true
            })
        },
        toggleManufacturerVariants(manufacturer) {
            this.manufacturersToggleList[manufacturer] = !this.manufacturersToggleList[manufacturer]
        },
        removeVariant(variant, manufacturerId, order) {
            this.productOrdersCart[manufacturerId][variant.id].orderQuantity -= order[1]
            this.productOrdersCart[manufacturerId][variant.id].orderIds[order[0]] = 0
            this.checkIfDeleteFields(variant, manufacturerId, order[0])
        },
        checkIfDeleteFields(variant, prodId, type, index, order = "directly") {
            if(type === 'list') {
                // let productOrders = Object.entries(this.productOrdersCart[prodId])
                // for(let i=0; i<productOrders.length; i++)
                // {
                //     productOrders[i][1].index = i+1;
                // }
                if(this.productOrdersCart[prodId][`${index+'-'+variant.item.variantId}`].orderQuantity === 0) 
                    delete this.productOrdersCart[prodId][`${index+'-'+variant.item.variantId}`]
                if(Object.values(this.productOrdersCart[prodId]).length === 0) {
                    delete this.productOrdersCart[prodId]
                    this.createToggleList()
                }
                this.index--;
            }
            else
            {
                if(this.productOrdersCart[prodId][variant.id].orderIds[order] === 0) 
                    delete this.productOrdersCart[prodId][variant.id].orderIds[order]
                if(this.productOrdersCart[prodId][variant.id].orderQuantity === 0) 
                    delete this.productOrdersCart[prodId][variant.id]
                if(Object.values(this.productOrdersCart[prodId]).length === 0) {
                    delete this.productOrdersCart[prodId]
                    this.createToggleList()
                }
            }
        },
        incrementVariant(variant, manufacturerId) {
            this.productOrdersCart[manufacturerId][variant.id].orderQuantity++
            this.productOrdersCart[manufacturerId][variant.id].orderIds["directly"]++
        },
        decrementVariant(variant, prodId, type, index) {
            if(type === 'list') {
                this.productOrdersCart[prodId][`${index+'-'+variant.item.variantId}`].orderQuantity--;
                this.checkIfDeleteFields(variant, prodId, type, index)
            } else {
                this.productOrdersCart[prodId][variant.id].orderQuantity--
                this.productOrdersCart[prodId][variant.id].orderIds["directly"]--
                this.checkIfDeleteFields(variant, prodId)
            }
        },
        async getOrderedProductsCart() {
            this.dbListenerCart = db.collection("ProductOrdersCart")
            .onSnapshot((querySnapshot) => {
                if(Object.keys(this.productOrdersCart).length > 0) {
                    let oldCart = JSON.parse(JSON.stringify(this.productOrdersCart))
                    let newCart = querySnapshot.docs[0].data()
                    for(let i = 0; i < Object.entries(oldCart).length; i++) {
                        let oldCartItem = Object.entries(oldCart)[i]
                        for(let y = 0; y < Object.values(oldCartItem[1]).length; y++) {
                            let oldVariant = Object.entries(oldCartItem[1])[y]
                            if(oldVariant[1].orderIds.directly !== undefined && oldVariant[1].orderIds.directly > 0) {
                                if(newCart[oldCartItem[0]] !== undefined && newCart[oldCartItem[0]][oldVariant[0]] !== undefined) {
                                    if(newCart[oldCartItem[0]][oldVariant[0]].orderIds.directly !== undefined) {
                                        newCart[oldCartItem[0]][oldVariant[0]].orderIds.directly += oldVariant[1].orderIds.directly
                                        newCart[oldCartItem[0]][oldVariant[0]].orderQuantity += oldVariant[1].orderIds.directly
                                    } else {
                                        newCart[oldCartItem[0]][oldVariant[0]].orderIds.directly = oldVariant[1].orderIds.directly
                                        newCart[oldCartItem[0]][oldVariant[0]].orderQuantity += oldVariant[1].orderIds.directly
                                    }
                                } else {
                                    newCart[oldCartItem[0]] = {
                                        [oldVariant[0]]: {
                                            id: oldVariant[1].id,
                                            item: oldVariant[1].item,
                                            orderIds: {directly: oldVariant[1].orderIds.directly},
                                            orderQuantity: oldVariant[1].orderIds.directly
                                        }
                                    }
                                }
                            } 
                        }
                    }
                    this.productOrdersCart = newCart
                } else if(querySnapshot.docs.length > 0) {
                    this.productOrdersCart = querySnapshot.docs[0].data()
                }
            })
        },
        addVariantToList(variant, type) {
            const manufacturerId = variant.item.manufacturer.id
            const variantId = variant.item.id
            if(type === 'custom')
            {
                let randomIndex = randomstring.generate(5)
                let str = variantId;
                let num = str.lastIndexOf("_");
                let prodId = str.slice(num+1, str.length);

                if(!this.productOrdersCart[prodId]) {
                    this.productOrdersCart[prodId] = {}
                }
                if(!this.productOrdersCart[prodId][`${randomIndex}-${variantId}`]) {
                    this.productOrdersCart[prodId][`${randomIndex}-${variantId}`] = {
                        item: {
                            id: prodId,
                            variantId: variantId,
                            name: variant.item.name,
                            image: variant.item.image,
                            amountOfPackages: variant.item.amountOfPackages
                        },
                        index: randomIndex,
                        printAmount: 1,
                        orderQuantity: variant.orderQuantity,
                    }
                } else {
                    this.productOrdersCart[prodId][`${randomIndex}-${variantId}`].printAmount += variant.orderQuantity
                    this.productOrdersCart[prodId][`${randomIndex}-${variantId}`].orderQuantity += variant.orderQuantity
                }
            }
            else
            {
                if(!variant.item.manufacturer.ref) {
                    return console.log("Missing manufacturer on variant")
                }
    
                let packages = {}
    
                for(let i=0; i<variant.item.amountOfPackages; i++)
                {
                    packages[i+1] = {
                        id: variantId + `_${i+1}`,
                        scanned: false,
                    }
                }
                
                if(!this.productOrdersCart[manufacturerId]) {
                    this.productOrdersCart[manufacturerId] = {}
                }
                if(!this.productOrdersCart[manufacturerId][variantId]) {
                    this.productOrdersCart[manufacturerId][variantId] = {
                        id: variantId,
                        orderQuantity: variant.orderQuantity,
                        item: {
                            name: variant.item.name,
                            image: variant.item.image,
                            amountOfPackages: variant.item.amountOfPackages
                        },
                        orderIds: {},
                        packages
                    }
                } else {
                    this.productOrdersCart[manufacturerId][variantId].orderQuantity += variant.orderQuantity
                }
                if(!this.productOrdersCart[manufacturerId][variantId].orderIds) {
                    this.productOrdersCart[manufacturerId][variantId].orderIds = {}
                }
                if(!this.productOrdersCart[manufacturerId][variantId].orderIds["directly"]) {
                    this.productOrdersCart[manufacturerId][variantId].orderIds["directly"] = variant.orderQuantity
                } else {
                    this.productOrdersCart[manufacturerId][variantId].orderIds["directly"] += variant.orderQuantity
                }
            }
        },
        async placeOrder() { 
            try {
                this.$store.commit('setGlobalLoader',{
                    show: true,
                    head: "Składanie zamówienia..",
                    subheader: `Nie zamykaj tego okna.`,
                });
                const res = await axios.post(`${this.$store.state.apiLink}/storage/order/place`, {
                    cart: this.productOrdersCart,
                    productOrdersDescriptions: this.productOrdersDescriptions,
                    meta: {
                        lastModificationDate: moment().toDate(),
                        createdBy: `${this.$store.state.userData.name} ${this.$store.state.userData.surname}`,
                        userId: this.$store.state.userData.id,
                        created: {
                            asText: moment().format("YYYY-MM-DD").toString(),
                            unix: moment().unix(),
                            date: moment().toDate(),
                            dayOfWeek: moment().day(),
                            dayNum: moment().date(),
                            monthNum: moment().month() + 1,
                            year: moment().year(),
                            weekNum: moment().week()
                        }
                    }
                })
                if(res.data.success) {
                    this.$store.commit('setNotification',{
                        show: true,
                        head: "Zamówienie zostało złożone.",
                        subheader: ``,
                        success: true
                    });
                } else {
                    this.$store.commit('setNotification',{
                        show: true,
                        head: "Nie udało się złożyć zamówienia.",
                        subheader: ``,
                        success: false
                    });
                }
            this.$router.push(`/dashboard/storage/orders/list`)
            } catch (error) {
                console.log(error);
                this.$store.commit('setNotification',{
                    show: true,
                    head: "Nie udało się złożyć zamówienia.",
                    subheader: ``,
                    success: false
                });
            }
        },
        createPositionsList(prod) {
            const variants = Object.entries(prod.variants).map((arr) =>  { 
                return {
                    item: arr[1],
                    orderQuantity: 1
            }});
            return variants
        },

        resetSearch()
        {
            this.products = [];
            this.searcher.found = [];
            this.searcher.lastCall = null;
            this.searcher.phrase = "";
            this.dbListener = db.collection("Products").orderBy("meta.createdDate", "desc").limit(this.queryLimit)
            .onSnapshot((querySnapshot) => {
                this.queryLoading = true;
                this.products = [];
                if(querySnapshot.docs.length<this.queryLimit)
                {
                    this.queryLimitReached = true;
                }
                else
                {
                    this.queryLimitReached = false;
                }
                querySnapshot.forEach((doc) => {
                    this.products.push(doc.data())
                    this.lastLoaded = doc.data().meta.createdDate;
                });
                this.queryLoading = false;
            });
        },
        async search()
        {
            if(this.searcher.phrase.length > 0)
            {
                this.searcher.locked = true;
                const res = await axios.post(`${this.$store.state.apiLink}/search`, {
                    index: "jan-art-products",
                    collection: "Products",
                    phrase: this.searcher.phrase,
                    searchFields: ['names','models','skus','eans','id','categories','manufacturers','variantsIds','baselinkerIds']

                })
                if(res.data.success)
                {
                    for(let i=0; i<res.data.found.length; i++) {
                        this.toggleInformation[i] = {
                            open: false
                        }
                    }
                    this.searcher.found = res.data.found;
                    if(res.data.found.length > 0)
                    {
                        this.searcher.lastCall = res.data.data;
                        this.getProducts();
                        this.$store.commit('setNotification',{
                            show: true,
                            head: "Wyszukiwanie zakończone!",
                            subheader: `Znaleziono ${res.data.found.length} produktów.`,
                            success: true
                        });

                    }else{
                        this.$store.commit('setNotification',{
                            show: true,
                            head: "Nie znaleziono wyników!",
                            subheader: `Spróbuj wyszukać inną frazę.`,
                            success: false
                        });
                    }

                }
                else
                {
                    this.$store.commit('setNotification',{
                        show: true,
                        head: "Wyszukiwanie zakończone niepowodzeniem!",
                        subheader: `Coś poszło nie tak...`,
                        success: false
                    });
                }
                setTimeout(()=>
                {
                    // this.searcher.phrase = "";
                    this.searcher.locked = false;
                }, 3000)
            }
            else
            {
                return;
            }
        },
        loadMoreProducts()
        {
            this.queryLimit = this.queryLimit + 10;
            this.getProducts()    
        },
        copyToClipboard(id)
        {
            navigator.clipboard.writeText(id).then(()=>
            {
                this.$store.commit('setNotification',{
                    show: true,
                    head: "Skopiowano do schowka!",
                    subheader: ``,
                    success: true
                }); 
            })
        },
        async getProducts()
        {
            this.loading = true;
            this.products = []
            this.query = null;
            if(this.dbListener !== null){
                this.dbListener()
                this.dbListener = null
            }

            this.query = db.collection("Products")
            this.query = this.query

            this.dbListener = this.query.limit(this.queryLimit)
            .onSnapshot((querySnapshot) => {
                this.products = [];
                if(querySnapshot.docs.length<this.queryLimit)
                {
                    this.queryLimitReached = true;
                }
                else
                {
                    this.queryLimitReached = false;
                }
                for(let i=0; i<querySnapshot.docs.length; i++)
                {
                    let current = querySnapshot.docs[i].data()
                    this.products.push(current);
                    this.toggleInformation[i] = {
                        open: false
                    }
                    this.lastLoaded = current.meta.createdDate;
                }
                this.loading = false;
            })
        },
        continueListening()
        {      
            this.queryLimit = this.queryLimit + 25;
            this.getProducts()
        },
        loadMoreProducts()
        {
            this.dbListener();
            this.continueListening();      
        },
    },
    async created() {
        this.moment = moment;
        moment.locale('pl');
        if(!this.$store.state.userData.permissions.admin && !this.$store.state.userData.permissions.storage) {
            this.$router.push("/hub")
        }
        await this.getOrderedProductsCart();
        this.getProducts();
        this.createToggleList();
        this.getAllManufacturers();
        this.getAllLists();
        this.changeTab(0)
    },
    beforeUnmount() {
        if(this.dbListener !== null){
            this.dbListener();
        }
        if(this.dbListenerLists !== null){
            this.dbListenerLists();
        }
        if(this.dbListenerCart !== null){
            this.dbListenerCart();
        }
        if(this.dbListenerManufacturer !== null){
            this.dbListenerManufacturer()
        }
    },
 }
</script>

<style lang="scss" scoped>
.fade-enter-active,
.fade-leave-active {
transition: opacity 0.5s ease;
}

.fade-enter-from,
.fade-leave-to {
opacity: 0;
}
</style>